<template>
  <v-autocomplete
    class="tp-filter-autocomplete"
    :items="items"
    dense
    v-model="model"
    deletable-chips
    :search-input="search"
    hide-details
    hide-selected
    :item-text="itemText"
    :item-value="itemValue"
    no-data-text="Không có dữ liệu"
    outlined
    chips
    single-line
    small-chips
    label="Outlined"
    :multiple="multiple"
    :placeholder="placeholder"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    placeholder: String,
    items: Array,
    multiple: Boolean,
    itemText: String,
    itemValue: String,
    search: Function,
    value: Array,
    updateValue: Function
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.updateValue(val);
      }
    }
  }
};
</script>
